import * as React from 'react'
import * as LabelPrimitive from '@radix-ui/react-label'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from 'lib/utils'
import { TooltipIcon } from 'components/icons'
import TooltipToggle from './tooltip-toggle'

const labelVariants = cva(
  'inline-block text-sm font-medium leading-none text-slate-600 dark:text-white',
)

export type LabelProps = React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
  VariantProps<typeof labelVariants> & { required?: boolean; tooltip?: string }

const Label = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, LabelProps>(
  ({ className, children, required, tooltip, ...props }, ref) => (
    <LabelPrimitive.Root ref={ref} className={cn(labelVariants(), className)} {...props}>
      <div className="flex items-center gap-0.5">
        {children}

        {tooltip && (
          <TooltipToggle
            triggerProps={{ asChild: true }}
            trigger={
              <div>
                <TooltipIcon className="ml-1 h-4 w-4 text-slate-500 dark:text-slate-400" />
              </div>
            }
          >
            <p>{tooltip}</p>
          </TooltipToggle>
        )}
      </div>
    </LabelPrimitive.Root>
  ),
)
Label.displayName = LabelPrimitive.Root.displayName

export { Label }
