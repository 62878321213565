import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { signIn } from 'next-auth/react'
import qs from 'qs'
import { WindowWithEthereum } from 'types/common'
import { Route } from 'constants/common/routes'

const getSigningCallbackUrl = ({ origin }: { origin?: string }) => {
  if (origin === 'desktop') {
    return Route.OpenDesktopApp
  }

  if (origin?.startsWith('/')) {
    return origin
  }
  return '/'
}

export const useAuth = () => {
  const { query } = useRouter()
  const [isMetamaskAllowed, setIsMetamaskAllowed] = useState(false)

  useEffect(() => {
    const ethereum = (window as WindowWithEthereum)?.ethereum

    if (!ethereum) {
      setIsMetamaskAllowed(false)
    } else {
      setIsMetamaskAllowed(true)
    }
  }, [])

  // sign up
  const cognitoGoogleSignUp = async () =>
    signIn('cognito_google', {
      callbackUrl: `${Route.Onboarding}${qs.stringify(query, { addQueryPrefix: true })}`,
    })

  const cognitoMetamaskAuth = async ({ type }: { type: 'signIn' | 'signUp' }) => {
    const ethereum = (window as WindowWithEthereum)?.ethereum

    if (!ethereum) {
      window.alert('Please install MetaMask first.')
      return
    }

    const signInCallbackUrl = getSigningCallbackUrl({ origin: query.origin as string })

    await signIn('cognito_metamask', {
      callbackUrl:
        type === 'signIn'
          ? signInCallbackUrl
          : `${Route.Onboarding}${qs.stringify(query, { addQueryPrefix: true })}`,
    })
  }

  const cognitoTwitterSignUp = async () =>
    signIn('cognito_twitter', {
      callbackUrl: `${Route.Onboarding}${qs.stringify(query, { addQueryPrefix: true })}`,
    })

  const signUpMethods = {
    cognitoGoogle: cognitoGoogleSignUp,
    cognitoMetamask: () => cognitoMetamaskAuth({ type: 'signUp' }),
    cognitoTwitter: cognitoTwitterSignUp,
  }

  // sign in
  const cognitoGoogleSignIn = async () =>
    signIn('cognito_google', {
      callbackUrl: getSigningCallbackUrl({ origin: query.origin as string }),
    })

  const cognitoTwitterSignIn = async () =>
    signIn('cognito_twitter', {
      callbackUrl: getSigningCallbackUrl({ origin: query.origin as string }),
    })

  const signInMethods = {
    cognitoGoogle: cognitoGoogleSignIn,
    cognitoMetamask: () => cognitoMetamaskAuth({ type: 'signIn' }),
    cognitoTwitter: cognitoTwitterSignIn,
  }

  return { signUpMethods, signInMethods, isMetamaskAllowed }
}
