import Image from 'next/image'

export const ConfirmationStep = () => (
  <>
    <Image src="loginConfirmationLogo.svg" alt="JamGalaxy logo" height={100} width={100} />
    <h1 className="text-center text-lg font-light text-white">
      You are connected with
      <br />
      your account!
    </h1>
  </>
)
