import { IconProps } from './types'

export const CloseIcon = (props: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_415_4940)">
      <mask
        id="mask0_415_4940"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <path d="M0 0H24V24H0V0Z" fill="currentColor" />
      </mask>
      <g mask="url(#mask0_415_4940)">
        <path
          d="M11.9952 13.4049L7.09517 18.3049C6.91183 18.4883 6.6785 18.5799 6.39517 18.5799C6.11183 18.5799 5.8785 18.4883 5.69517 18.3049C5.51183 18.1216 5.42017 17.8883 5.42017 17.6049C5.42017 17.3216 5.51183 17.0883 5.69517 16.9049L10.5952 12.0049L5.69517 7.10493C5.51183 6.9216 5.42017 6.68826 5.42017 6.40493C5.42017 6.1216 5.51183 5.88826 5.69517 5.70493C5.8785 5.5216 6.11183 5.42993 6.39517 5.42993C6.6785 5.42993 6.91183 5.5216 7.09517 5.70493L11.9952 10.6049L16.8952 5.70493C17.0785 5.5216 17.3118 5.42993 17.5952 5.42993C17.8785 5.42993 18.1118 5.5216 18.2952 5.70493C18.4785 5.88826 18.5702 6.1216 18.5702 6.40493C18.5702 6.68826 18.4785 6.9216 18.2952 7.10493L13.3952 12.0049L18.2952 16.9049C18.4785 17.0883 18.5702 17.3216 18.5702 17.6049C18.5702 17.8883 18.4785 18.1216 18.2952 18.3049C18.1118 18.4883 17.8785 18.5799 17.5952 18.5799C17.3118 18.5799 17.0785 18.4883 16.8952 18.3049L11.9952 13.4049Z"
          fill="currentColor"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_415_4940">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
