import Image from 'next/image'
import { CommonButton } from 'components/common-button'
import { Button } from 'components/ui/button'

export const FinalStep = () => (
  <>
    <div className="flex flex-col items-center gap-6">
      <Image src="logo.svg" alt="JamGalaxy logo" height={100} width={100} />
      <div className="flex w-full flex-col items-center">
        <h1 className="text-lg font-light text-white">Log in as hello@example.com</h1>
      </div>
    </div>
    <CommonButton className="w-full">Open the desktop app</CommonButton>
    <div className="flex flex-col items-center">
      <p className="text-xs text-white-transparent60">Not hello@example.com?</p>
      <Button className="h-fit py-0 text-xs text-primary-transparent60" variant="link">
        Log in with another account
      </Button>
    </div>
  </>
)
